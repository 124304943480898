import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'
import Arrow from '../../assets/icons/chevron.svg'
import { modifyLangString } from '../../lib/utils/modifyLangString'
import LogoOfC from '../../assets/svg/LogoOfC.svg'
import MoreButton from '../common/MoreButton'

function MedicalFifthSection() {
	const { t, i18n } = useTranslation()
	const currentLanguage = modifyLangString(i18n.language)

	const data = useStaticQuery(graphql`
		query {
			ontolForClinics: file(relativePath: { eq: "images/medical/ProductSection5.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			ontol3D_en: file(relativePath: { eq: "images/medical/ontol3D2_en.png" }) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth id="ontol-for-clinics">
			<Block>
				<TitleContainer>
					<TitleMainAndBeta>
						<LogoOfC />
					</TitleMainAndBeta>
					<TitleSub1>{t('MedicalFifthSection.3')}</TitleSub1>
				</TitleContainer>
				<ImageWrapper>
					<Image
						data={{
							placeholderImage:
								currentLanguage !== 'en' ? data.ontolForClinics : data.ontol3D_en,
						}}
					/>
				</ImageWrapper>
				<DescriptionAndButtonContainer>
					<DescriptionText>{t('MedicalFifthSection.4')}</DescriptionText>
					<MoreButton link="/contact" text={t('MedicalFifthSection.5')} />
				</DescriptionAndButtonContainer>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	@media (min-width: 1440px) {
		width: 1214px;
		margin-top: 220px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		margin-top: 180px;
	}
	@media (max-width: 768px) {
		width: 320px;
		margin-top: 100px;
	}
`

const TitleContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		padding-left: 104px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
	}
	@media (max-width: 768px) {
	}
`

const TitleMainAndBeta = styled.a`
	display: flex;
	align-items: flex-end;
	cursor: pointer;
`

const TitleSub1 = styled.div`
	font-weight: 700;
	white-space: pre-wrap;

	@media (min-width: 1440px) {
		margin-top: 50px;
		font-size: 56px;
		line-height: 64px; /* 114.286% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		margin-top: 30px;
		margin-bottom: 50px;
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		margin-top: 20px;
		font-size: 24px;
		line-height: 32px; /* 133.333% */
	}
`

const ImageWrapper = styled.div`
	@media (min-width: 1440px) {
		padding: 0px 100px;
		padding-top: 64px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 64px;
	}
	@media (max-width: 768px) {
		position: absolute;
		left: -20px;
		padding-top: 48px;
		width: 100vw; /* 화면 너비와 동일하게 설정 */
	}
`

const DescriptionAndButtonContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 32px;

	@media (min-width: 1440px) {
		padding-top: 120px;
		padding-left: 100px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		padding-top: 64px;
	}
	@media (max-width: 768px) {
		padding-top: calc(100vw * 242 / 361 + 64px + 48px);
	}
`

const DescriptionText = styled.div`
	font-weight: 500;

	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 160% */
		white-space: pre-line;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
`

export default MedicalFifthSection
