import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { down, only, up } from 'styled-breakpoints'

import SEO from '../components/base/SEO'
import Layout from '../components/base/Layout'
import MedicalIntroSection from '../components/product/MedicalIntroSection'
import MedicalSecondSection from '../components/product/MedicalSecondSection'
import MedicalThirdSection from '../components/product/MedicalThirdSection'
import MedicalCTASection from '../components/product/MedicalCTASection'

import InvertColorsContext from '../context/InvertColorsContext'
import useWindowSize from '../lib/hooks/useWindowSize'
import MedicalFourthSection from '../components/product/MedicalFourthSection'
import MedicalFifthSection from '../components/product/MedicalFifthSection'
import MedicalSixthSection from '../components/product/MedicalSixthSection'
import MedicalSeventhSection from '../components/product/MedicalSeventhSection'

const metaData = {
	title: '제품 | Tesser',
	description:
		'환자에게 이해하기 쉬운 의료 데이터를 제공하는 모바일 앱 온톨과 의료영상을 직관적인 3D로 설명해주는 온톨 3D를 개발합니다.',
	keywords:
		'온톨, 검사결과지 해석, 환자 커뮤니티, 의료영상, 의료데이터, 임상데이터, 뇌종양, brain tumor, MRI, 뇌스캔, f-mri, 암진단, 유방암, 의무기록지 해석, 검사결과 설명, 의료 언어모델, 의료 LLM, 헬스케어 데이터, 환자 데이터, 환자 서비스, 온톨 앱',
}

function Medical() {
	const windowSize = useWindowSize()
	const [innerHeight, setInnerHeight] = useState<number | null>(null)

	useEffect(() => {
		if (windowSize.height) {
			setInnerHeight(windowSize.height)
		}
	}, [windowSize])

	useEffect(() => {
		const onRouteUpdate = () => {
			setTimeout(() => {
				const location = window.location

				if (location.pathname.startsWith('/product') === true && location.hash) {
					const id = location.hash.replace('#', '')
					const element = document.getElementById(id)

					if (element) {
						element.scrollIntoView({ block: 'start' })
					}
				}
			}, 500)
		}

		onRouteUpdate()
	})

	if (!innerHeight) {
		return (
			<Layout color="black" blackFooter medicalPage>
				<SEO metaData={metaData} />
				<Block></Block>
			</Layout>
		)
	}

	return (
		<Layout color="black" blackFooter medicalPage>
			<SEO metaData={metaData} />
			<InvertColorsContext.Consumer>
				{({ invertColors }) => (
					<Block>
						<MedicalIntroSection
							innerHeight={innerHeight}
							invertColors={invertColors}
						/>
						<MedicalSecondSection />
						<MedicalThirdSection />
						<MedicalFourthSection />
						<MedicalFifthSection />
						<MedicalSixthSection />
						<MedicalSeventhSection />
						<MedicalCTASection/>
					</Block>
				)}
			</InvertColorsContext.Consumer>
		</Layout>
	)
}

const Block = styled.div`
	min-height: 100vh;
	margin: 0 auto;

	> *:not(:last-child) {
		${up('xl')} {
			margin-bottom: 160px;
		}
		${only('lg')} {
			margin-bottom: 160px;
		}
		${only('md')} {
			margin-bottom: 160px;
		}
		${only('sm')} {
			margin-bottom: 160px;
		}
		${down('xs')} {
			margin-bottom: 120px;
		}
	}
`

export default Medical
