import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'
import MoreButton from '../common/MoreButton'

function MedicalSeventhSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			Image1: file(
				relativePath: { eq: "images/medical/ProductSection7_11.jpg" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Image2: file(
				relativePath: { eq: "images/medical/ProductSection7_22.jpg" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth>
			<Block>
				<ContentsArea>
					<TitleContainer>
						<Title>{t('MedicalSeventhSection.title')}</Title>
					</TitleContainer>
					<ItemsBox>
						<DescriptionBox1>
							<DescriptionTextContainer>
								<DescriptionTitle>{t('MedicalSeventhSection.1-1')}</DescriptionTitle>
								<DescriptionContent>
									{t('MedicalSeventhSection.1-2')}
								</DescriptionContent>
								<div>
									<DescriptionFeaturesTitle>
										{t('MedicalSeventhSection.features')}
									</DescriptionFeaturesTitle>
									<DescriptionFeatures>
										<ul>
											<li>{t('MedicalSeventhSection.1-3-1')}</li>
											<li>{t('MedicalSeventhSection.1-3-2')}</li>
											<li>{t('MedicalSeventhSection.1-3-3')}</li>
											<li>{t('MedicalSeventhSection.1-3-4')}</li>
										</ul>
									</DescriptionFeatures>
								</div>
								<MoreButton text={t('MedicalSeventhSection.1-4')} link='/contact'/>
							</DescriptionTextContainer>
							<DescriptionImageWrapper>
								<Image data={{ placeholderImage: data.Image1 }} />
							</DescriptionImageWrapper>
						</DescriptionBox1>
						<DescriptionBox2>
							<DescriptionTextContainer>
								<DescriptionTitle>{t('MedicalSeventhSection.2-1')}</DescriptionTitle>
								<DescriptionContent>
									{t('MedicalSeventhSection.2-2')}
								</DescriptionContent>
								<div>
									<DescriptionFeaturesTitle>
										{t('MedicalSeventhSection.features')}
									</DescriptionFeaturesTitle>
									<DescriptionFeatures>
										<ul>
											<li>{t('MedicalSeventhSection.2-3-1')}</li>
											<li>{t('MedicalSeventhSection.2-3-2')}</li>
											<li>{t('MedicalSeventhSection.2-3-3')}</li>
										</ul>
									</DescriptionFeatures>
								</div>
								<MoreButton text={t('MedicalSeventhSection.2-4')} link='/contact'/>
							</DescriptionTextContainer>
							<DescriptionImageWrapper>
								<Image data={{ placeholderImage: data.Image2 }} />
							</DescriptionImageWrapper>
						</DescriptionBox2>
					</ItemsBox>
				</ContentsArea>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	@media (min-width: 1440px) {
		width: 1214px;
		padding-top: 150px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const ContentsArea = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		gap: 100px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 64px;
	}
	@media (max-width: 768px) {
		gap: 48px;
	}
`

const ItemsBox = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		gap: 160px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 64px;
	}
	@media (max-width: 768px) {
		gap: 48px;
	}
`

const TitleContainer = styled.div`
	display: flex;
	width: 100%;
`

const Title = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 72px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 44px;
	}
`

const DescriptionBox1 = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: row;
		gap: 32px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
		gap: 32px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 25px;
	}
`

const DescriptionBox2 = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: row;
		gap: 32px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
		gap: 32px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 25px;
	}
`

const DescriptionImageWrapper = styled.div`
	@media (min-width: 1440px) {
		width: 592px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 450px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const DescriptionTextContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		justify-content: center;
		width: 592px;
		gap: 25px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 25px;
		width: 500px;
	}
	@media (max-width: 768px) {
		width: 320px;
		gap: 25px;
	}
`

const DescriptionTitle = styled.div`
	font-weight: 700;white-space: pre-wrap;

	@media (min-width: 1440px) {
		font-size: 45px;
		line-height: 60px; /* 88.889% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		line-height: 36px; /* 128.571% */
	}
	@media (max-width: 768px) {
		font-size: 24px;
		line-height: 32px; /* 133.333% */
	}
`

const DescriptionContent = styled.div`
	font-weight: 400;
	white-space: pre-line;
	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 160% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
`


const DescriptionFeaturesTitle = styled.div`
	font-weight: 700;
	
	@media (min-width: 1440px) {
		font-size: 24px;
		line-height: 32px; /* 160% */
		margin-bottom: 10px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 22px;
		line-height: 28px; /* 155.556% */
	}
	@media (max-width: 768px) {
		font-size: 10px;
		line-height: 28px; /* 155.556% */
	}
`

const DescriptionFeatures = styled.div`
	font-weight: 400;
	white-space: pre-line;
	@media (min-width: 1440px) {
		font-size: 18px;
		line-height: 32px; /* 160% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 16px;
		line-height: 28px; /* 155.556% */
	}
	@media (max-width: 768px) {
		font-size: 14px;
		line-height: 28px; /* 155.556% */
	}

	ul {
		padding-left: 30px;
	}

	li {
		padding-left: 10px;
		padding-bottom: 5px;
	}
`


export default MedicalSeventhSection
