import React, { useEffect } from 'react'
import styled from 'styled-components'
import gsap from 'gsap'
import { useBreakpoint } from 'gatsby-plugin-breakpoints'
import { useTranslation } from 'react-i18next'

import { fullWidth } from '../../lib/styles/utils'
import Typography from '../common/Typography'
import GradientCanvas from '../main/GradientCanvas'
import zIndexes from '../../lib/styles/zIndexes'
import { ColorType } from '../../context/InvertColorsContext'

interface IMedicalIntroSectionProps {
	innerHeight: number
	invertColors: (colorType: ColorType) => void
}

function MedicalIntroSection({
	innerHeight,
	invertColors,
}: IMedicalIntroSectionProps) {
	const breakpoints = useBreakpoint()
	useEffect(() => {
		invertColors(ColorType.WHITE)
	}, [])

	useEffect(() => {
		const defaultHeight = 16
		const scaleValue = innerHeight / defaultHeight
		const timeline = gsap.timeline({
			scrollTrigger: {
				trigger: '.container',
				start: 'top top',
				end: () => innerHeight,
				scrub: true,
				pin: '.container',
				anticipatePin: 1,
			},
		})

		timeline
			.set('.gradientBox', { scaleY: 1 })
			.to('.gradientBox', { duration: 0.1, scaleY: scaleValue + 15 }, 0.001)

		return () => {
			timeline.kill()
		}
	}, [])

	const gradientColors = {
		color1: '#00F3AA ',
		color2: '#0094FF',
		color3: '#1D41FF',
		color4: '#1AFFD6',
	}

	const { t } = useTranslation()

	return (
		<Block className="container">
			<Content>
				<TextBlock className="text">
					<Typography.Display size="xlarge" fontWeight="bold">
						{breakpoints.sm ? (
							<>{t('MedicalIntroSection.heading1')}</>
						) : (
							<>{t('MedicalIntroSection.heading2')}</>
						)}
					</Typography.Display>
				</TextBlock>
				<GradientBlock className="gradientBox">
					<GradientCanvas gradientColors={gradientColors} />
				</GradientBlock>
			</Content>
		</Block>
	)
}

const Block = styled.section`
	height: 100vh;
	margin-bottom: 8rem;
`

const TextBlock = styled.div`
	display: flex;
	flex-direction: column;
	position: relative;
	z-index: ${zIndexes.medicalIntroText};
	padding: 20px;
	word-break: keep-all;
`

const GradientBlock = styled.div`
	width: 100%;
	height: 16px;
	position: absolute;
	overflow: hidden;
	display: flex;
	align-items: center;
	animation: fadeIn 0.8s;
`

const Content = styled.div`
	height: 100%;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	position: relative;

	${fullWidth};
`

export default MedicalIntroSection
