import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'

function MedicalThirdSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			PhoneInHand: file(
				relativePath: { eq: "images/medical/PhoneInHand.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth>
			<Block>
				<TitleContainer>
					<Title>{t('MedicalThirdSection.1')}</Title>
				</TitleContainer>
				<ImageAndReviewContainer>
					<Image1Wrapper>
						<Image data={{ placeholderImage: data.PhoneInHand }} />
					</Image1Wrapper>
					<ReviewsContainer>
						<ReviewBox>
							<ReviewText>{t('MedicalThirdSection.2')}</ReviewText>
							<Reviewer>{t('MedicalThirdSection.3')}</Reviewer>
						</ReviewBox>
						<ReviewBox>
							<ReviewText>{t('MedicalThirdSection.4')}</ReviewText>
							<Reviewer>{t('MedicalThirdSection.5')}</Reviewer>
						</ReviewBox>
						<ReviewBox>
							<ReviewText>{t('MedicalThirdSection.6')}</ReviewText>
							<Reviewer>{t('MedicalThirdSection.7')}</Reviewer>
						</ReviewBox>
						<ReviewBox>
							<ReviewText>{t('MedicalThirdSection.8')}</ReviewText>
							<Reviewer>{t('MedicalThirdSection.9')}</Reviewer>
						</ReviewBox>
					</ReviewsContainer>
				</ImageAndReviewContainer>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	position: relative;

	@media (min-width: 1440px) {
		width: 1008px;
		padding-top: 160px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
		padding-top: 160px;
	}
	@media (max-width: 768px) {
		width: 320px;
		/* padding-top: 112px; */
	}
`

const TitleContainer = styled.div`
	display: flex;
	width: 100%;
`

const Title = styled.div`
	font-weight: 700;

	@media (min-width: 1440px) {
		font-size: 60px;
		line-height: 72px; /* 120% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 48px;
		line-height: 56px; /* 116.667% */
	}
	@media (max-width: 768px) {
		font-size: 36px;
		line-height: 44px;
	}
`

const ImageAndReviewContainer = styled.div`
	@media (min-width: 1440px) {
		display: flex;
		justify-content: space-between;
		gap: 32px;
		margin-top: 40px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		display: flex;
		flex-direction: column;
		gap: 48px;
		margin-top: 48px;
	}
	@media (max-width: 768px) {
		display: flex;
		flex-direction: column;
		gap: 32px;
		margin-top: 32px;
	}
`

const Image1Wrapper = styled.div`
	@media (min-width: 1440px) {
		width: 488px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 326px;
	}
	@media (max-width: 768px) {
		width: 326px;
	}
`

const ReviewsContainer = styled.div`
	display: flex;
	flex-direction: column;
	gap: 16px;
`

const ReviewBox = styled.div`
	border-radius: 15px;
	background: #f6f8f9;
	display: flex;
	flex-direction: column;
	align-items: flex-start;
	padding: 22px 28px;

	@media (min-width: 1440px) {
		width: 488px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		align-self: stretch;
	}
	@media (max-width: 768px) {
		align-self: stretch;
	}
`

const ReviewText = styled.div`
	font-size: 18px;
	font-style: normal;
	font-weight: 500;
	color: #555;
	line-height: 30px; /* 156.25% */
`

const Reviewer = styled.div`
	font-size: 14px;
	font-style: normal;
	font-weight: 600;
	line-height: 24px; /* 150% */
	color: #aaa;
	margin-top: 8px;
`

export default MedicalThirdSection
