import React from 'react'
import styled from 'styled-components'
import { useTranslation } from 'react-i18next'

import { graphql, useStaticQuery } from 'gatsby'
import Image from '../common/Image'

function MedicalSixthSection() {
	const { t } = useTranslation()

	const data = useStaticQuery(graphql`
		query {
			Image1: file(
				relativePath: { eq: "images/medical/ProductSection6_1.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
			Image2: file(
				relativePath: { eq: "images/medical/ProductSection6_2.png" }
			) {
				childImageSharp {
					fluid {
						...GatsbyImageSharpFluid_tracedSVG
					}
				}
			}
		}
	`)

	return (
		<FullWidth>
			<Block>
				<ContentsArea>
					<DescriptionBox1>
						<DescriptionTextContainer>
							<DescriptionTitle>{t('MedicalSixthSection.1')}</DescriptionTitle>
							<DescriptionContent>
								{t('MedicalSixthSection.2')}
							</DescriptionContent>
						</DescriptionTextContainer>
						<DescriptionImageWrapper>
							<Image data={{ placeholderImage: data.Image1 }} />
						</DescriptionImageWrapper>
					</DescriptionBox1>
					<DescriptionBox2>
						<DescriptionTextContainer>
							<DescriptionTitle>{t('MedicalSixthSection.3')}</DescriptionTitle>
							<DescriptionContent>
								{t('MedicalSixthSection.4')}
							</DescriptionContent>
						</DescriptionTextContainer>
						<DescriptionImageWrapper>
							<Image data={{ placeholderImage: data.Image2 }} />
						</DescriptionImageWrapper>
					</DescriptionBox2>
				</ContentsArea>
			</Block>
		</FullWidth>
	)
}

const FullWidth = styled.section`
	position: relative;
	display: flex;
	flex-direction: column;
	align-items: center;
	width: 100%;
`

const Block = styled.div`
	@media (min-width: 1440px) {
		width: 1214px;
		padding-top: 150px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 688px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const ContentsArea = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		gap: 100px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 64px;
	}
	@media (max-width: 768px) {
		gap: 48px;
	}
`

const DescriptionBox1 = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: row;
		gap: 32px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
		gap: 32px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 25px;
	}
`

const DescriptionBox2 = styled.div`
	display: flex;

	@media (min-width: 1440px) {
		flex-direction: row-reverse;
		gap: 32px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		flex-direction: column;
		gap: 32px;
	}
	@media (max-width: 768px) {
		flex-direction: column;
		gap: 25px;
	}
`

const DescriptionImageWrapper = styled.div`
	@media (min-width: 1440px) {
		width: 592px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		width: 450px;
	}
	@media (max-width: 768px) {
		width: 320px;
	}
`

const DescriptionTextContainer = styled.div`
	display: flex;
	flex-direction: column;

	@media (min-width: 1440px) {
		justify-content: center;
		width: 592px;
		gap: 25px;
		padding: 50px;
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		gap: 25px;
		padding: 50px;
		padding-bottom: 15px;
		width: 500px;
	}
	@media (max-width: 768px) {
		width: 320px;
		gap: 25px;
	}
`

const DescriptionTitle = styled.div`
	font-weight: 700;white-space: pre-wrap;

	@media (min-width: 1440px) {
		font-size: 45px;
		line-height: 60px; /* 88.889% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 28px;
		line-height: 36px; /* 128.571% */
	}
	@media (max-width: 768px) {
		font-size: 24px;
		line-height: 32px; /* 133.333% */
	}
`

const DescriptionContent = styled.div`
	font-weight: 400;
	white-space: pre-line;
	@media (min-width: 1440px) {
		font-size: 20px;
		line-height: 32px; /* 160% */
	}
	@media (min-width: 768px) and (max-width: 1440px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
	@media (max-width: 768px) {
		font-size: 18px;
		line-height: 28px; /* 155.556% */
	}
`

export default MedicalSixthSection
